import brianLogo from "../assets/brianLogo.svg";

const MenuBox = (props) => {
  const { onMenuSelect } = props;

  const handleMenuSelect = (sectionName) => {
    onMenuSelect(sectionName);
  };

  //<button onClick={() => handleMenuSelect("contact")}>
  //<h2>Contact</h2>
//</button>

  return (
    <div className="menuBox">
      <img src={brianLogo} alt="" onClick={() => handleMenuSelect("home")} />

      <div className="panel">
        <button onClick={() => handleMenuSelect("home")}>
          <h1>
            <div>Brian</div>
            <div>Hendrickson</div>
          </h1>
        </button>

        <svg
          className="circleLine"
          viewBox="0 0 104 10"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="4" cy="2" r="1.5" fill="#666666" stroke="0" />
          <circle cx="16" cy="2" r="1.5" fill="#666666" stroke="0" />
          <circle cx="28" cy="2" r="1.5" fill="#666666" stroke="0" />
          <circle cx="40" cy="2" r="1.5" fill="#666666" stroke="0" />
          <circle cx="52" cy="2" r="1.5" fill="#666666" stroke="0" />
          <circle cx="64" cy="2" r="1.5" fill="#666666" stroke="0" />
          <circle cx="76" cy="2" r="1.5" fill="#666666" stroke="0" />
          <circle cx="88" cy="2" r="1.5" fill="#666666" stroke="0" />
          <circle cx="100" cy="2" r="1.5" fill="#666666" stroke="0" />
        </svg>


        <button onClick={() => handleMenuSelect("uiux")}>
          <h2>UI / UX</h2>
        </button>

        <button onClick={() => handleMenuSelect("illustration")}>
          <h2>Illustration</h2>
        </button>
        <button onClick={() => handleMenuSelect("landscapes")}>
          <h2>Landscapes</h2>
        </button>

      

      </div>
    </div>
  );
};
export default MenuBox;
